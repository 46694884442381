import React, { useState } from "react"
import styled from "styled-components"
import { ethers } from "ethers"

const NODE_KEY = "c047fc2c05444ccfbdf60ee05013f08a";

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Input = styled.input`
    width: 100%;
    padding: 0.5em;
    margin: 0.5em 0;
`
const NextButton = styled.div`
    padding: 0.5em;
    border: 1px solid #999;
    border-radius: 0.5em;
    width: fit-content;

    &:hover { 
        cursor: pointer;
    }
`
const Error = styled.div`
    margin: 0.5em 0;
    padding: 0.25em;
    background: #BE5125;
`

const InputUserOrGroup = (props: any) => {
    const [error, setError] = useState<string | null>(null)

    const userInputRef = React.useRef<HTMLInputElement>(null);

    const handleClick = async () => {
        setError(``)
        let inputName = userInputRef.current?.value ?? "";
        inputName = inputName.trim();

        if(inputName.match(/^[a-zA-Z0-9\-\_\.]{1,214}$/)) {
            const name = inputName.trim()
            props.callback({
                nextStep: 1,
                name: name,
            })
        } else {
            setError(`Invalid name. Only the following character sets: A-Z 0-9 _ - .`)
        }
    }

    return (<>
        What user/group would you like to check?

        <SubTitle>
            Enter a user, such as <code>foo</code> so we can analyse the results
        </SubTitle>

        {error && <Error>{error}</Error>}
        <Input ref={userInputRef} type="text" id="address" placeholder="foo" />
        <NextButton onClick={() => handleClick()}>Next</NextButton>
    </>)
}

export default InputUserOrGroup
import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/Layout"
import styled from "styled-components"

import InputUserOrGroup from './1.InputUserOrGroup'
import AnalyseUser from './2.AnalyseUser'

interface IStep {
    selected: boolean;
    canSelect: boolean;
}

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Stepper = styled.div`
    display: block;
    border: 1px solid #999;
    width: fit-content;
    padding: 0 2em;
    margin: 2em 0;
`
const Steps = styled.ul`
    list-style-type: none;
`
const Step = styled.li<IStep>`
    display: inline-block;
    padding: 0.5em 1em;
    background: ${props => props.selected ? `#353535` : `transparent`};

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }

    @media (max-width: 992px) {
        display: block;
    }
`
const ResetButton = styled.div<IStep>`
    display: block;
    margin: 2em 0;
    padding: 0.5em 1em;
    background: #9B6161;
    width: fit-content;

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }
`

interface IState {
    step: number; /* the step which to render */
    name: string; /* the name of the user or group */
    previousName: string; /* the name of the previous search */
}

const DEFAULT_STATE = {
    name: "",
    previousName: "",
    step: 0
}

class SupplyChain extends React.Component<{}, IState> {
    state: IState = DEFAULT_STATE;

    constructor() {
        super({});

        this.renderSwitch = this.renderSwitch.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    updateState(args: any) {
        this.setState({
            ...this.state,
            ...args,
            step: args.nextStep
        })
    }

    renderSwitch(step: number) {
        switch(step) {
            default :
            case 0 :
                return <InputUserOrGroup 
                            callback={this.updateState} 
                        />
            case 1 :
                return <AnalyseUser 
                            name={this.state.name}
                            previousName={this.state.previousName}
                            callback={this.updateState}
                        />
        }
    }

    render() {
        return (
            <Layout>
            <Title>
                <Link to="/">~/</Link>
                <Link to="/tools">tools/</Link>&nbsp;SupplyChain
            </Title>
            <SubTitle>
                Get some data about who you rely on with your dependencies within the npm ecosystem!
            </SubTitle>
            <SubTitle>
                This tool makes use data exposed on the npmjs website so you can have a better understanding on the{' '}
                security of the dependencies you are relying on. It might give you data to help against supply-chain{' '}
                attacks, and maybe even make business cases on forking that repo under your own namespace!
            </SubTitle>

            <Stepper>
                <Steps>
                    <Step 
                        onClick={() => this.state.step > 0 && this.updateState({...DEFAULT_STATE})} 
                        selected={this.state.step == 0}
                        canSelect={this.state.step > 0}
                    >
                        1. Input a user/group
                    </Step>
                    <Step 
                        // onClick={() => this.state.step > 1 && this.updateState({nextStep: 1})} 
                        selected={this.state.step == 1}
                        canSelect={false && this.state.step > 1}
                    >
                        2. Analyse results
                    </Step>
                </Steps>
            </Stepper>

            {this.renderSwitch(this.state.step)}

            {this.state.step > 0 && <ResetButton onClick={() => this.state.step > 0 && this.updateState({nextStep: 0})} selected={false} canSelect={true}>Start Over</ResetButton>}

            </Layout>
        );
    }
};

export default SupplyChain;
